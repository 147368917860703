$container: 1280px;
$black: #212121;
$yellow: #f9a825;
$tablet: 968px;

body {
    position: relative;
    overflow-x: hidden;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 60px;
    flex-direction: column;
    background-color: rgb(33 33 33 / 53%);
    position: absolute;
    width: 100%;
    z-index: 5;
    
    @media screen and (max-width: $tablet) {
        padding: 0 16px;
    }
    
    &__logo {
        filter: brightness(0) invert(1);
        
        & img {
            width: 300px;
            height: auto;
        }
    }
    
    &__list {
        list-style: none;
        text-align: left;
        display: flex; 
        padding: 0;   
        margin: -10px 0 0;
        
        li {
            
            padding-bottom: 16px;
            &:not(:last-child) {
                padding-right: 60px; 
                
                @media screen and (max-width: $tablet) {
                    padding-right: 24px;
                }
            }
            a {
                text-decoration: none;
                color: $yellow;
                position: relative;
                transition: ease-in .03s;
                &:after {
                    content: '';
                    height: 0;
                    position: absolute;
                    transition: all .3s;
                }
                
                &:hover {
                    &:after {
                        background-color: $yellow;
                        width: 100%;
                        height: 6px;
                        bottom: -16px;
                        left: 0;
                    }
                    width: 10px;
                    border-bottom: 4px;
                    border-color: $yellow;
                }
                
            }
        }
    }
}

.carousel {
    position: relative;
    
    &__img {
        width: 100%;
        height: 800px;
        object-fit: cover;
        object-position: center;
    }
    
    &__text {
        background-color: rgb(0 0 0 / 50%);
        padding: 20px;
        color: #fff;
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 55%;
        transform: translate(-50%, -50%);
        
        @media screen and (max-width: $tablet) {
            width: 90%;
        }
    }
    
    &__btn {
        background-color: $yellow;
        padding: 16px;
        color: $black;
        display: block;
        text-decoration: none;
        font-weight: bold;
    }
    
    &__item {
        position: relative;
        
        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: rgb(0 0 0 / 56%);
            z-index: 1;
        }
    }
}

.about {
    background-color: $black;
    margin-top: -4px;
    padding: 42px 60px;
    display: flex;
    align-items: center;
    text-align: left;
    color: $yellow;
    font-size: 14px;

    @media screen and (max-width: $tablet) {
        flex-direction: column;
        padding: 40px 16px 80px;
    }

    > div {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;

    @media screen and (max-width: $tablet) {
        width: 100%;
    }
    }

    p {
        line-height: 24px;

    }

    
    & img {
            filter: brightness(0) invert(1);
            width: 300px;
            height: auto;
            text-align: center;
        }
}

.cta {
    display: flex;
    justify-content: space-around;
    margin-top: -4px;
    
    & > a {
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: bold;
        height: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    &__instagram {
        background:linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
    }
    
    &__whatsapp {
        background:linear-gradient(45deg, #b5f778, #1EBEA5, #00E676);
    }
}
.section {
    &--dark {
        background-color: rgb(33 33 33 / 83%);
        mix-blend-mode: multiply;
        color: #fff;
        position: relative;
        
        &::after {
            content: '';
            position: absolute;
            min-width: 100%;
            height: 100%;
            background: url('../img/carousel-1.jpg');
            background-size: cover;
            top: 0;
            right: 0;
            mix-blend-mode: multiply;
            z-index: -1;
            
        }
    }
    
    &__title {
        background-color: $black;
        padding-top: 80px;
        padding-bottom: 16px;
        margin-top: -4px;
        
        h2, h3, {
            margin: 0;
            color: $yellow
        }
        
        h3, p {
            color: #fff;
            margin-top: 10px
        }
    }
}

.gallery {
    
    &__item img{
        width: 100%;
        height: 300px;
        object-fit: cover;
        
        @media screen and (max-width: $tablet) {
            height: 600px;
        }
    }
}

.contact {
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 60px 60px;
        text-align: left;
        
        @media screen and (max-width: $tablet) {
            flex-direction: column;
        }
        
        > div {
            width: 50%;
            
            @media screen and (max-width: $tablet) {
                width: 100%;
                margin-bottom: 100px;
            } 
        } 
        
        h4 {
            font-size: 24px;
            margin-bottom: 42px;
        }
        
        li { 
            margin: 0; 
            font-size: 20px;
            &::marker {
                color: $yellow
            }
        }
        
        p {
            font-size: 20px;
        }
        
        a {
            color: $yellow;
            font-weight: bold;
        }
        
    }
    &__btn {
        height: 100%;
        text-align: center;
        
        &::after {
            content: "";
            position: absolute;
            width: 50%;
            height: 100%;
            background-color: $yellow;
            background-size: cover;
            top: 0;
            right: 0;
            mix-blend-mode: multiply;
            z-index: -1;
            
            @media screen and (max-width: $tablet) {
                width: 100%;
                height: 200px;
                top: auto;
                bottom: 0;
                left: 0;
                
            }
        }
        
        a {
            background-color: $yellow;
            color: $black;
            padding: 16px 80px;
            text-align: center;
            width: 30%;
            text-decoration: none;
            transition: all .3s;
            
            &:hover {
                background-color: $black;
                color: $yellow;
            }
        }
    }
}

.footer {
    background-color: $yellow;
    height: 42px;
    width: 100%;
}